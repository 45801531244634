// extracted by mini-css-extract-plugin
export var amountButtonContainer = "subscriptions-module--amount-button-container--Up-GL";
export var circleGreen = "subscriptions-module--circle-green--wfg-J";
export var circleRed = "subscriptions-module--circle-red--me1Fa";
export var editModalForm = "subscriptions-module--editModalForm--hv571";
export var formLabels = "subscriptions-module--formLabels--wqot5";
export var readOnlyField = "subscriptions-module--readOnlyField--4oucn";
export var recoverItemsToggle = "subscriptions-module--recover-items-toggle--pGx2f";
export var toggle = "subscriptions-module--toggle--8CSb8";
export var toggleField = "subscriptions-module--toggle-field--iwt1S";
export var toggleLabel = "subscriptions-module--toggle-label--0ZieI";
export var toggleWarningText = "subscriptions-module--toggle-warning-text--mg5m+";