import React, { useState, useMemo, useEffect } from 'react'

import {
   CrudActionButtons,
   LoadingOverlay,
   PageHeader,
   Search,
} from '../../common'
import { SnackBarState } from '../../common/snackBar/types'
import SnackBar, { closedSnackbarState } from '../../common/snackBar/snackBar'
import { Severity } from '../../../types'
import { Actions, PAGINATION } from '../../../constants/tables'
import { TableWrapper } from '../../common/table/TableWrapper'
import { useForm, useSubscriptions } from '../../../hooks'
import { TableColumnCellWrapper } from '../../common/table/tableColumnCellWrapper'
import { WarningModal } from '../../common/warningModal/warningModal'
import {
   currencyFormatter,
   formatUsDate,
   renderDateOrNothing,
} from '../../../common/utils/functions'
import { getPlanTypeFromId } from '../../../common/utils/planTypes'
import {
   convertStringToNumber,
   formatAllowedNumber,
   getCurrencyNumericValue,
} from './helpers'
import EditSubscriptionModal from './editSubscriptionModal'
import {
   Subscription,
   UpdateSubscriptionPayload,
} from '../../../hooks/useSubscriptions/types'
import { subscriptionSchema } from './constants'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'
import useGlobalPrices from '../../../hooks/useGlobalPrices/useGlobalPrices'

const Subscriptions = () => {
   const [snackbar, setSnackbar] = useState<SnackBarState>(closedSnackbarState)
   const [searchCriteria, setSearchCriteria] = useState<string>('')
   const [isSearchMode, setIsSearchMode] = useState<boolean>(false)
   const [pageSized, setPageSized] = useState<number>(PAGINATION.pageSized)
   const [isPageReset, setIsPageReset] = useState<boolean>(false)
   const [isPaginationReset, setIsPaginationReset] = useState<boolean>(false)
   const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
   const [deletionId, setDeletionId] = useState<string | null>(null)
   const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
   const [selectedSubscription, setSelectedSubscription] =
      useState<Subscription | null>(null)

   const {
      subscriptions,
      isError,
      isLoading,
      isRefetching,
      setListOptions,
      refetchSubscriptions,
      isDeleteError,
      isDeleting,
      isDeleteSuccess,
      deleteSubscription,
      isUpdateError,
      isUpdateSuccess,
      isUpdating,
      updateSubscriptionDetails,
   } = useSubscriptions()

   const { prices, isLoading: isGlobalPricesLoading } = useGlobalPrices()

   const { onSubmit, validationErrors, onReset } = useForm(
      subscriptionSchema,
      (values) => {
         const updatedSubscription: UpdateSubscriptionPayload = {
            UserID: selectedSubscription.UserID,
            IsActive: selectedSubscription.IsActive,
            Status: selectedSubscription.Status,
            StartDate: selectedSubscription.StartDate,
            EnterpriseUser: selectedSubscription.EnterpriseUser,
            IsCheckedRecoverOrDelete:
               selectedSubscription.IsCheckedRecoverOrDelete,
            ExpiryDate: formatUsDate(selectedSubscription.ExpiryDate),
            ID: convertStringToNumber(values.ID),
            PlanType: getPlanTypeFromId(Number(selectedSubscription.PlanType)),
            CustomerProfileID: convertStringToNumber(values.CustomerProfileID),
            PaymentProfileID: convertStringToNumber(values.PaymentProfileID),
            AnnualBasePrice: getCurrencyNumericValue(values.AnnualBasePrice),
            NumberOfUsers: convertStringToNumber(values.NumberOfUsers),
            AnnualPlanUserPrice: getCurrencyNumericValue(
               values.AnnualPlanUserPrice
            ),
            MonthlyBasePrice: getCurrencyNumericValue(values.MonthlyBasePrice),
            MonthlyPlanUserPrice: getCurrencyNumericValue(
               values.MonthlyPlanUserPrice
            ),
            Amount: getCurrencyNumericValue(values.Amount),
            NumberOfAllowedItems: convertStringToNumber(
               values.NumberOfAllowedItems
            ),
            NumberOfAllowedVirtualUsers: convertStringToNumber(
               values.NumberOfAllowedVirtualUsers
            ),
         }
         updateSubscriptionDetails(updatedSubscription)
      }
   )

   useEffect(() => {
      if (isError) {
         handleEditModalClose()
         setSnackbar({
            message: 'Error loading subscriptions.',
            severity: Severity.ERROR,
         })
      }
   }, [isError])

   useEffect(() => {
      if (isUpdateSuccess) {
         handleEditModalClose()
         setSnackbar({
            message: 'The subscription was updated.',
            severity: Severity.SUCCESS,
         })
         refetchSubscriptions()
      }
   }, [isUpdateSuccess])

   useEffect(() => {
      if (isUpdateError) {
         handleEditModalClose()
         setSnackbar({
            message: 'Error updating subscription details.',
            severity: Severity.ERROR,
         })
      }
   }, [isUpdateError])

   useEffect(() => {
      if (isDeleteSuccess) {
         handleEditModalClose()
         setSnackbar({
            message: 'The subscription was deleted.',
            severity: Severity.SUCCESS,
         })
         refetchSubscriptions()
      }
   }, [isDeleteSuccess])

   useEffect(() => {
      if (isDeleteError) {
         handleEditModalClose()
         setSnackbar({
            message: 'Error deleting the subscription.',
            severity: Severity.ERROR,
         })
      }
   }, [isDeleteError])

   const handleGetSubscriptions = (
      query?: string,
      skip?: number,
      take?: number
   ) => {
      const requestPayload = {
         query,
         skip,
         take,
      }
      setListOptions(requestPayload)
   }

   const handleSearchQuery = (query?: string, skip?: number, take?: number) => {
      setIsPaginationReset(true)
      if (query) {
         setSearchCriteria(query)
      }
      handleGetSubscriptions(query, skip, take)
   }

   const handleDeleteSubscription = async () => {
      await deleteSubscription(deletionId)
      setDeletionId(null)
      setDeleteModalOpen(false)
   }

   const handleDropdownChange = (fieldName: string, option: Option) => {
      setSelectedSubscription((prevState) => ({
         ...prevState,
         [fieldName]: option.value,
      }))
   }

   const handleToggleChange = (name: string, checked: boolean) => {
      setSelectedSubscription((prevState) => ({
         ...prevState,
         [name]: checked,
      }))
   }

   const handleDateChange = (name: string, date: Date) => {
      setSelectedSubscription((prevState) => ({
         ...prevState,
         [name]: date,
      }))
   }

   const showActionForm = (action: Actions, id: string) => {
      switch (action) {
         case Actions.Delete:
            setDeletionId(id)
            setDeleteModalOpen(true)
            break
         case Actions.Edit:
            const selected = subscriptions?.Subscriptions.find(
               (sub) => sub.ID === Number(id)
            )
            if (selected) {
               setSelectedSubscription(selected)
               setEditModalOpen(true)
            }
            break
         default:
            break
      }
   }

   const columns = useMemo(
      () => [
         {
            Header: 'Actions',
            accessor: 'ID',
            id: 'CTA',
            width: 150,
            minWidth: 60,
            align: 'center',
            Cell: (cell) => (
               <>
                  <CrudActionButtons
                     cell={cell}
                     action={showActionForm}
                     editAction
                     deleteAction
                  />
               </>
            ),
         },
         {
            Header: 'First Name',
            accessor: 'FirstName',
            id: 'FirstName',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.FirstName ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Last Name',
            accessor: 'LastName',
            id: 'LastName',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.LastName ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Company Name',
            accessor: 'CompanyName',
            id: 'CompanyName',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.CompanyName ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'City',
            accessor: 'City',
            id: 'City',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.City ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'State',
            accessor: 'State',
            id: 'State',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.State ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Country',
            accessor: 'Country',
            id: 'Country',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Country ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Email',
            accessor: 'Email',
            id: 'Email',
            minWidth: 300,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Email ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Phone Number',
            accessor: 'Phone',
            id: 'Phone',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Phone ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Customer Profile ID',
            accessor: 'CustomerProfileID',
            id: 'CustomerProfileID',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.CustomerProfileID ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Payment Profile ID',
            accessor: 'PaymentProfileID',
            id: 'PaymentProfileID',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.PaymentProfileID ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Plan Type',
            accessor: 'PlanType',
            id: 'PlanType',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={getPlanTypeFromId(row.original.PlanType)}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Number Of Users',
            accessor: 'NumberOfUsers',
            id: 'NumberOfUsers',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.NumberOfUsers ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Amount',
            accessor: 'Amount',
            id: 'Amount',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={currencyFormatter(row.original.Amount)}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Annual Base Price',
            accessor: 'AnnualBasePrice',
            id: 'AnnualBasePrice',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={currencyFormatter(row.original.AnnualBasePrice)}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Annual Plan User Price',
            accessor: 'AnnualPlanUserPrice',
            id: 'AnnualPlanUserPrice',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={currencyFormatter(row.original.AnnualPlanUserPrice)}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Monthly Base Price',
            accessor: 'MonthlyBasePrice',
            id: 'MonthlyBasePrice',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={currencyFormatter(row.original.MonthlyBasePrice)}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Monthly Plan User Price',
            accessor: 'MonthlyPlanUserPrice',
            id: 'MonthlyPlanUserPrice',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={currencyFormatter(row.original.MonthlyPlanUserPrice)}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Status',
            accessor: 'Status',
            id: 'Status',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Status ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Start Date',
            accessor: 'StartDate',
            id: 'StartDate',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'Expiry Date',
            accessor: 'ExpiryDate',
            id: 'ExpiryDate',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'Trial Start Date',
            accessor: 'TrialStartDate',
            id: 'TrialStartDate',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'User Created Date',
            accessor: 'UserCreatedDate',
            id: 'UserCreatedDate',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'User Trial Upgrade Date',
            accessor: 'TrialUpgradeDate',
            id: 'TrialUpgradeDate',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'Subscription Upgrade Date',
            accessor: 'SubscriptionUpgradeDate',
            id: 'SubscriptionUpgradeDate',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'Last Login Date',
            accessor: 'LastLoginDate',
            id: 'LastLoginDate',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'Number Of Tool Records',
            accessor: 'ToolRecords',
            id: 'ToolRecords',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.ToolRecords ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Transaction Activity (past 30 days)',
            accessor: 'LoanRecordsCreated',
            id: 'LoanRecordsCreated',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.LoanRecordsCreated ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Number Of Admin Connections',
            accessor: 'AdminConnectionsCount',
            id: 'AdminConnectionsCount',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.AdminConnectionsCount ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Number Of Employee Connections',
            accessor: 'EmployeeConnectionsCount',
            id: 'EmployeeConnectionsCount',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.EmployeeConnectionsCount ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Number Of Virtual Connections',
            accessor: 'VirtualConnectionsCount',
            id: 'VirtualConnectionsCount',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.VirtualConnectionsCount ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Allowed Items',
            accessor: 'NumberOfAllowedItems',
            id: 'NumberOfAllowedItems',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={formatAllowedNumber(
                     row.original.NumberOfAllowedItems,
                     100
                  )}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Allowed Virtual Users',
            accessor: 'NumberOfAllowedVirtualUsers',
            id: 'NumberOfAllowedVirtualUsers',
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={formatAllowedNumber(
                     row.original.NumberOfAllowedVirtualUsers,
                     10
                  )}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Starter Created Date',
            accessor: 'StarterCreatedDate',
            id: 'StarterCreatedDate',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'Starter To Trial Upgrade Date',
            accessor: 'StarterToTrialUpgrade',
            id: 'StarterToTrialUpgrade',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'Starter Upgrade Date',
            accessor: 'StarterUpgradeDate',
            id: 'StarterUpgradeDate',
            align: 'center',
            Cell: renderDateOrNothing,
         },
      ],
      [subscriptions?.Subscriptions]
   )

   const handleEditModalClose = () => {
      setEditModalOpen(false)
      onReset()
      setSelectedSubscription(null)
   }

   const loading = useMemo(
      () =>
         isLoading ||
         isRefetching ||
         isUpdating ||
         isDeleting ||
         isGlobalPricesLoading,
      [isLoading, isRefetching, isUpdating, isDeleting, isGlobalPricesLoading]
   )

   return (
      <>
         <PageHeader title="Subscriptions" />
         {loading && <LoadingOverlay />}
         <SnackBar
            open={
               snackbar.message !== '' && snackbar.severity !== Severity.INFO
            }
            message={snackbar.message}
            severity={snackbar.severity}
            resetMessageStateHandler={() => setSnackbar(closedSnackbarState)}
         />
         <Search
            handleQuery={handleSearchQuery}
            searchMode={isSearchMode}
            setSearchMode={setIsSearchMode}
            pageSized={pageSized}
            placeHolder={' Subscriptions'}
            setSearchCriteria={setSearchCriteria}
            isPageSearchReset={isPageReset}
            setIsPageSearchReset={setIsPageReset}
         />
         {!isLoading && (
            <TableWrapper
               columns={columns}
               data={subscriptions?.Subscriptions as []}
               getItems={handleGetSubscriptions}
               skipItems={PAGINATION.skip}
               takeItems={PAGINATION.take}
               totalCount={subscriptions?.TotalCount ?? 0}
               extraClassName=""
               isLoading={loading}
               isPageReset={isPaginationReset}
               setIsPageReset={setIsPaginationReset}
               searchCriteria={searchCriteria}
               searchMode
               setItemId={(_id) => null}
               setPageSized={setPageSized}
               setSearchMode={() => null}
               hideResultsTitle
            />
         )}
         {deleteModalOpen && (
            <WarningModal
               isModalOpen={deleteModalOpen}
               handleClose={() => setDeleteModalOpen(false)}
               title="Delete Subscription"
               message="Are you sure you want to delete this subscription?"
               primaryButtonTitle="Delete"
               primaryButtonMethod={() => handleDeleteSubscription()}
               primaryButtonVariant="danger"
               secondaryButtonTitle="Cancel"
               secondaryButtonMethod={() => setDeleteModalOpen(false)}
               secondaryButtonVariant="tertiary"
            />
         )}
         {editModalOpen && (
            <EditSubscriptionModal
               isOpen={editModalOpen}
               handleClose={handleEditModalClose}
               subscriptionDetails={selectedSubscription}
               onSubmit={onSubmit}
               validationErrors={validationErrors}
               handleDropdownChange={handleDropdownChange}
               handleToggleChange={handleToggleChange}
               handleDateChange={handleDateChange}
               globalPrices={prices}
            />
         )}
      </>
   )
}

export default Subscriptions
