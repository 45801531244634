const formatAllowedNumber = (num: number, limit: number) =>
   num > limit ? 'Unlimited' : num

const convertStringToNumber = (value: string | null) =>
   value === '' || value === null ? 0 : Number(value)

const getCurrencyNumericValue = (currency: string | number) => {
   return typeof currency === 'number'
      ? currency
      : parseFloat(currency.replace(/[$,]/g, ''))
}

export { formatAllowedNumber, convertStringToNumber, getCurrencyNumericValue }
