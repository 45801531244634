import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { useAPI } from '..'
import { PATH } from '../../constants/global'

import { GlobalPrices, UseGlobalPricesData } from './useGlobalPrices.type'

export default function (): UseGlobalPricesData {
   const request = useAPI()

   async function getGlobalPricesFromAPI(): Promise<AxiosResponse> {
      return await request.get(PATH.GLOBAL.PRICES)
   }

   const {
      data,
      isLoading,
      isError,
      refetch: refetchGlobalPrices,
      isRefetching,
   } = useQuery(
      'global-prices',
      (): Promise<AxiosResponse> => getGlobalPricesFromAPI()
   )

   return {
      prices: data?.data || ({} as GlobalPrices),
      isLoading,
      isRefetching,
      isError,
      refetchGlobalPrices,
   }
}
