import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'react-grid-system'

import { Button, Modal } from '../../common'
import { DateInput, Dropdown, Input, Toggle } from '../../common/form/fields'

import { parseDate } from '../../../common/utils/functions'
import { EditSubscriptionModalProps, SubscriptionTotals } from './types'
import { subscriptionPlanTypeOptions } from './constants'
import * as styles from './subscriptions.module.scss'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'
import { getPlanTypeFromId, PlanTypes } from '../../../common/utils/planTypes'
import { NumericFormat } from 'react-number-format'

const EditSubscriptionModal = ({
   isOpen,
   handleClose,
   subscriptionDetails,
   onSubmit,
   validationErrors,
   handleDropdownChange,
   handleToggleChange,
   handleDateChange,
   globalPrices,
}: EditSubscriptionModalProps) => {
   const [planType, setPlanType] = useState<number>(
      subscriptionDetails.PlanType
   )
   const defaultTotals: SubscriptionTotals = {
      monthlyBase: subscriptionDetails.MonthlyBasePrice ?? 0,
      monthlyUser: subscriptionDetails.MonthlyPlanUserPrice ?? 0,
      annualBase: subscriptionDetails.AnnualBasePrice ?? 0,
      annualUser: subscriptionDetails.AnnualPlanUserPrice ?? 0,
      numberOfUsers: subscriptionDetails.NumberOfUsers,
   }
   const [totals, setTotals] = useState<SubscriptionTotals>(defaultTotals)
   const [amount, setAmount] = useState<number>(subscriptionDetails.Amount)

   useEffect(() => {
      if (subscriptionDetails?.Amount) {
         setTimeout(() => {
            setAmount(subscriptionDetails.Amount)
         }, 500) // wait until totals state has been set first
      }
   }, [subscriptionDetails.Amount])

   const handlePlanTypeChange = (selectedPlanType: Option) => {
      setPlanType(Number(selectedPlanType.value))
   }

   const defaultPlanType = useMemo(
      () =>
         subscriptionPlanTypeOptions.filter(
            (opt) => Number(opt.value) === planType
         ),
      [planType]
   )

   const planTypeName = useMemo(() => getPlanTypeFromId(planType), [planType])

   useEffect(() => {
      switch (planTypeName) {
         case PlanTypes.EnterpriseMonthly: {
            const monthlyBase = totals.monthlyBase
               ? totals.monthlyBase
               : subscriptionDetails.MonthlyBasePrice
               ? subscriptionDetails.MonthlyBasePrice
               : globalPrices.MonthlyBasePrice
            const monthlyUser = totals.monthlyUser
               ? totals.monthlyUser
               : subscriptionDetails.MonthlyPlanUserPrice
               ? subscriptionDetails.MonthlyPlanUserPrice
               : globalPrices.MonthlyPlanUserPrice
            setTotals((prevState) => ({
               ...prevState,
               monthlyBase,
               monthlyUser,
            }))
            break
         }
         case PlanTypes.EnterpriseYearly: {
            const annualBase = totals.annualBase
               ? totals.annualBase
               : subscriptionDetails.AnnualBasePrice
               ? subscriptionDetails.AnnualBasePrice
               : globalPrices.AnnualBasePrice
            const annualUser = totals.annualUser
               ? totals.annualUser
               : subscriptionDetails.AnnualPlanUserPrice
               ? subscriptionDetails.AnnualPlanUserPrice
               : globalPrices.AnnualPlanUserPrice
            setTotals((prevState) => ({
               ...prevState,
               annualBase,
               annualUser,
            }))
            break
         }
         case PlanTypes.Starter:
         case PlanTypes.Trial:
         default:
            break
      }
   }, [planTypeName])

   const handleTotalsChange = (
      key: keyof SubscriptionTotals,
      value: number
   ) => {
      setTotals((prevState) => ({ ...prevState, [key]: value }))
   }

   useEffect(() => {
      let amountToSet = 0
      switch (planTypeName) {
         case PlanTypes.EnterpriseMonthly:
            amountToSet =
               totals.monthlyBase + totals.monthlyUser * totals.numberOfUsers
            break
         case PlanTypes.EnterpriseYearly:
            amountToSet =
               totals.annualBase + totals.annualUser * 12 * totals.numberOfUsers
            break
         default:
            amountToSet = 0
            break
      }
      setAmount(amountToSet)
   }, [planTypeName, totals])

   const handleReCalculateClick = () =>
      setTotals((prevState) => ({ ...prevState })) // set totals in state to call amount useEffect

   const customAmountSet = useMemo(() => {
      switch (planTypeName) {
         case PlanTypes.EnterpriseMonthly:
            return (
               amount !==
               totals.monthlyBase + totals.monthlyUser * totals.numberOfUsers
            )
         case PlanTypes.EnterpriseYearly:
            return (
               amount !==
               totals.annualBase + totals.annualUser * 12 * totals.numberOfUsers
            )
         default:
            return false
      }
   }, [planTypeName, totals, amount])

   return (
      <Modal
         isModalVisible={isOpen}
         closeModal={handleClose}
         title="Edit Subscription"
      >
         <form noValidate onSubmit={onSubmit} className={styles.editModalForm}>
            <input
               name="ID"
               id="ID"
               type="hidden"
               value={subscriptionDetails.ID}
            />
            <input
               name="AnnualBasePrice"
               id="AnnualBasePrice"
               type="hidden"
               value={totals.annualBase}
            />
            <input
               name="AnnualPlanUserPrice"
               id="AnnualPlanUserPrice"
               type="hidden"
               value={totals.annualUser}
            />
            <input
               name="MonthlyBasePrice"
               id="MonthlyBasePrice"
               type="hidden"
               value={totals.monthlyBase}
            />
            <input
               name="MonthlyPlanUserPrice"
               id="MonthlyPlanUserPrice"
               type="hidden"
               value={totals.monthlyUser}
            />
            <input name="Amount" id="Amount" type="hidden" value={amount} />
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="FirstName"
                     label="First Name"
                     value={subscriptionDetails.FirstName}
                     disabled
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="LastName"
                     label="Last Name"
                     value={subscriptionDetails.LastName}
                     disabled
                  />
               </Col>
            </Row>
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="CompanyName"
                     label="Company Name"
                     value={subscriptionDetails.CompanyName}
                     disabled
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="City"
                     label="City"
                     value={subscriptionDetails.City}
                     disabled
                  />
               </Col>
            </Row>
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="State"
                     label="State"
                     value={subscriptionDetails.State}
                     disabled
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="Country"
                     label="Country"
                     value={subscriptionDetails.Country}
                     disabled
                  />
               </Col>
            </Row>
            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="Email"
                     label="Email Address"
                     value={subscriptionDetails.Email}
                     disabled
                     inputProps={{
                        type: 'email',
                     }}
                     noMargin
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="Phone"
                     label="Phone Number"
                     value={subscriptionDetails.Phone}
                     disabled
                     noMargin
                  />
               </Col>
            </Row>

            <hr />

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="CustomerProfileID"
                     label="Customer Profile ID"
                     value={subscriptionDetails.CustomerProfileID}
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="PaymentProfileID"
                     label="Payment Profile ID"
                     value={subscriptionDetails.PaymentProfileID}
                  />
               </Col>
            </Row>

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Dropdown
                     defaultValue={defaultPlanType}
                     name="PlanType"
                     onChange={(option: any) => {
                        handlePlanTypeChange(option)
                        handleDropdownChange('PlanType', option)
                     }}
                     options={subscriptionPlanTypeOptions}
                     id="PlanType"
                     label="Plan Type"
                     placeholder="PlanType"
                     isClearable={false}
                     required
                  />
               </Col>
               <Col xs={6}>
                  <DateInput
                     id="ExpiryDate"
                     label="Expiry Date"
                     minDate={new Date()}
                     onChange={(date: Date) =>
                        handleDateChange('ExpiryDate', date)
                     }
                     selectedDate={parseDate(subscriptionDetails.ExpiryDate)}
                     inputProps={{
                        type: 'number',
                     }}
                  />
               </Col>
            </Row>

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <div className={styles.toggle}>
                     <span className={styles.toggleField}>
                        <Toggle
                           id="EnterpriseUser"
                           size="md"
                           isChecked={subscriptionDetails.EnterpriseUser}
                           onToggle={handleToggleChange}
                        />
                     </span>
                     <span className={styles.toggleLabel}>Enterprise User</span>
                  </div>
               </Col>
               {planTypeName !== PlanTypes.Starter && (
                  <Col xs={6}>
                     <div className={styles.recoverItemsToggle}>
                        <span className={styles.toggleField}>
                           <Toggle
                              id="IsCheckedRecoverOrDelete"
                              size="md"
                              isChecked={
                                 subscriptionDetails.IsCheckedRecoverOrDelete
                              }
                              onToggle={handleToggleChange}
                           />
                        </span>
                        <span className={styles.toggleLabel}>
                           Recover Items &amp; Locations
                        </span>
                     </div>
                     <div className={styles.toggleWarningText}>
                        If checked, this will restore soft-deleted items and
                        locations affected by a plan downgrade, if any.
                     </div>
                  </Col>
               )}
            </Row>

            <hr />

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <NumericFormat
                     customInput={Input}
                     id="AnnualBasePrice"
                     label="Annual Base Price"
                     value={totals.annualBase}
                     disabled={planTypeName !== PlanTypes.EnterpriseYearly}
                     onValueChange={(values) => {
                        handleTotalsChange('annualBase', values.floatValue ?? 0)
                     }}
                     allowNegative={false}
                     thousandSeparator=","
                     decimalSeparator="."
                     decimalScale={2}
                     fixedDecimalScale
                     isControlled
                     prefix="$"
                  />
               </Col>
               <Col xs={6}>
                  <NumericFormat
                     customInput={Input}
                     id="AnnualPlanUserPrice"
                     label="Annual Plan User Price"
                     value={totals.annualUser}
                     disabled={planTypeName !== PlanTypes.EnterpriseYearly}
                     onValueChange={(values) => {
                        handleTotalsChange('annualUser', values.floatValue ?? 0)
                     }}
                     allowNegative={false}
                     thousandSeparator=","
                     decimalSeparator="."
                     decimalScale={2}
                     fixedDecimalScale
                     isControlled
                     prefix="$"
                  />
               </Col>
            </Row>

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <NumericFormat
                     customInput={Input}
                     id="MonthlyBasePrice"
                     label="Monthly Base Price"
                     value={totals.monthlyBase}
                     disabled={planTypeName !== PlanTypes.EnterpriseMonthly}
                     onValueChange={(values) => {
                        handleTotalsChange(
                           'monthlyBase',
                           values.floatValue ?? 0
                        )
                     }}
                     allowNegative={false}
                     thousandSeparator=","
                     decimalSeparator="."
                     decimalScale={2}
                     fixedDecimalScale
                     isControlled
                     prefix="$"
                  />
               </Col>
               <Col xs={6}>
                  <NumericFormat
                     customInput={Input}
                     id="MonthlyPlanUserPrice"
                     label="Monthly Plan User Price"
                     value={totals.monthlyUser}
                     disabled={planTypeName !== PlanTypes.EnterpriseMonthly}
                     onValueChange={(values) => {
                        handleTotalsChange(
                           'monthlyUser',
                           values.floatValue ?? 0
                        )
                     }}
                     allowNegative={false}
                     prefix="$"
                     thousandSeparator=","
                     decimalSeparator="."
                     decimalScale={2}
                     fixedDecimalScale
                     isControlled
                  />
               </Col>
            </Row>

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="NumberOfUsers"
                     label="Number of Users"
                     error={validationErrors?.NumberOfUsers}
                     value={totals.numberOfUsers}
                     onChange={(e) =>
                        handleTotalsChange(
                           'numberOfUsers',
                           Number(e.target.value)
                        )
                     }
                     inputProps={{
                        type: 'number',
                     }}
                     required
                  />
               </Col>
            </Row>

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <NumericFormat
                     id="Amount"
                     label="Amount"
                     customInput={Input}
                     value={amount}
                     onValueChange={(values) => {
                        setAmount(values.floatValue)
                     }}
                     allowNegative={false}
                     prefix="$"
                     thousandSeparator=","
                     decimalSeparator="."
                     decimalScale={2}
                     fixedDecimalScale
                     isControlled
                  />
               </Col>
               <Col xs={6}>
                  <div className={styles.amountButtonContainer}>
                     <span
                        className={
                           customAmountSet
                              ? styles.circleRed
                              : styles.circleGreen
                        }
                     />
                     <Button
                        type="button"
                        variant="tertiary"
                        onClick={handleReCalculateClick}
                        small
                        disabled={
                           planTypeName === PlanTypes.Starter ||
                           planTypeName === PlanTypes.Trial
                        }
                     >
                        Re-Calculate Amount
                     </Button>
                  </div>
               </Col>
            </Row>

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Input
                     id="NumberOfAllowedItems"
                     label="Allowed Items"
                     value={subscriptionDetails.NumberOfAllowedItems}
                     inputProps={{
                        type: 'number',
                     }}
                     noMargin
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="NumberOfAllowedVirtualUsers"
                     label="Allowed Virtual Users"
                     value={subscriptionDetails.NumberOfAllowedVirtualUsers}
                     inputProps={{
                        type: 'number',
                     }}
                     noMargin
                  />
               </Col>
            </Row>

            <hr />

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Button minWidth="100%" type="submit">
                     Save
                  </Button>
               </Col>
               <Col xs={6}>
                  <Button
                     type="button"
                     minWidth="100%"
                     variant="tertiary"
                     onClick={handleClose}
                     isReset
                  >
                     Cancel
                  </Button>
               </Col>
            </Row>
         </form>
      </Modal>
   )
}

export default EditSubscriptionModal
