import * as yup from 'yup'

import { SubscriptionDetails } from './types'
import { PlanTypes } from '../../../common/utils/planTypes'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'

const defaultSubscription: SubscriptionDetails = {
   ID: 0,
   CompanyName: null,
   City: null,
   State: null,
   Email: null,
   UserID: null,
   CustomerProfileID: null,
   PaymentProfileID: null,
   PlanType: 0,
   Amount: 0,
   NumberOfUsers: null,
   AnnualBasePrice: null,
   MonthlyBasePrice: null,
   AnnualPlanUserPrice: null,
   MonthlyPlanUserPrice: null,
   Status: null,
   StartDate: null,
   ExpiryDate: null,
   TrialStartDate: null,
   UserCreatedDate: null,
   TrialUpgradeDate: null,
   SubscriptionUpgradeDate: null,
   IsDeleted: false,
   IsActive: false,
   LastLoginDate: null,
   ToolRecords: 0,
   LoanRecordsCreated: 0,
   AdminConnectionsCount: 0,
   EmployeeConnectionsCount: 0,
   VirtualConnectionsCount: 0,
   FirstName: null,
   LastName: null,
   Phone: null,
   Country: null,
   NumberOfAllowedItems: 0,
   NumberOfAllowedVirtualUsers: 0,
   DisplayNumberOfAllowedItems: '',
   DisplayNumberOfAllowedVirtualUsers: '',
   StarterCreatedDate: null,
   StarterToTrialUpgrade: null,
   StarterUpgradeDate: null,
   IsCheckedRecoverOrDelete: false,
   EnterpriseUser: false,
}

const subscriptionPlanTypeOptions: Option[] = [
   {
      value: '0',
      label: PlanTypes.Trial,
   },
   {
      value: '1',
      label: PlanTypes.EnterpriseMonthly,
   },
   {
      value: '2',
      label: PlanTypes.EnterpriseYearly,
   },
   {
      value: '3',
      label: PlanTypes.Starter,
   },
]

const requiredErrorMsg = 'This field is required'

const subscriptionSchema = yup.object().shape({
   ID: yup.string(),
   PlanType: yup.string().required(requiredErrorMsg),
   NumberOfUsers: yup.string().required(requiredErrorMsg),
})

export {
   defaultSubscription,
   subscriptionPlanTypeOptions,
   requiredErrorMsg,
   subscriptionSchema,
}
